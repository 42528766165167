import type { SiteConfig } from '@config/type';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { COMMERCIAL_PROPERTY_USE_TYPE } from '@type/search/filters/useTypes';

export * from '@config/type';

export const SITE_CONFIG: SiteConfig = {
    metadata: {
        preconnectStaticsCDN: 'https://statics.imovirtual.com',
        siteURL: 'www.imovirtual.com',
        siteName: 'Imovirtual',
        defaultLanguage: 'pt',
        name: {
            robots: 'index, follow',
            description:
                'Imovirtual: De T0 a T-Tudo! Procura casa para arrendar? Tem apartamento a venda? Pesquise classificados de imobiliárias ou particulares em Portugal: Algarve, Lisboa, Porto, ...',
            'google-play-app': 'app-id=com.fixeads.imovirtual',
        },
        property: {
            'fb:app_id': '425781864171729',
            'og:title': 'Comprar e arrendar casa | Imovirtual',
            'og:description':
                'Imovirtual: De T0 a T-Tudo! Procura casa para arrendar? Tem apartamento a venda? Pesquise classificados de imobiliárias ou particulares em Portugal: Algarve, Lisboa, Porto, ...',
            'og:image':
                'https://statics.imovirtual.com/static/imovirtualpt/naspersclassifieds-regional/verticalsre-atlas-web-imovirtualpt/static/img/fb/fb-image200x200.png?t=20-11-10',
            'og:type': 'website',
            'og:url': 'https://www.imovirtual.com/',
            'og:site_name': 'www.imovirtual.com/',
            'al:web:url': 'https://www.imovirtual.com/',
            'al:web:should_fallback': 'true',
        },
        links: [
            {
                rel: 'canonical',
                href: 'https://www.imovirtual.com/',
            },
            {
                rel: 'icon',
                href: 'https://statics.imovirtual.com/static/imovirtualpt2/naspersclassifieds-regional/verticalsre-atlas-web-imovirtualpt2/static/img/favicon.ico',
            },
            {
                rel: 'apple-touch-icon',
                href: 'https://statics.imovirtual.com/static/imovirtualpt2/naspersclassifieds-regional/verticalsre-atlas-web-imovirtualpt2/static/img/app-icon.png',
            },
            {
                rel: 'android-touch-icon',
                href: 'https://statics.imovirtual.com/static/imovirtualpt2/naspersclassifieds-regional/verticalsre-atlas-web-imovirtualpt2/static/img/app-icon.png',
            },
        ],
        sellWithUs: false,
    },
    locales: [
        {
            code: 'pt',
            label: 'frontend.navbar.language-switcher.pt',
        },
    ],
    helpCenterLinks: [
        {
            code: 'pt',
            link: 'https://ajuda.imovirtual.com',
        },
    ],
    helpCenterPaymentPageLinks: {
        partner: 'https://clientes.imovirtual.com/',
        learnMore: 'https://ajuda.imovirtual.com/imovirtualhelp/s/',
        learnAboutAds:
            'https://ajuda.imovirtual.com/imovirtualhelp/s/article/O-que-%C3%A9-uma-exibi%C3%A7%C3%A3o-no-Imovirtual',
    },
    helpCenterSortingInfoLinks: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPD5CAM',
    tracking: {
        ninjaScriptUrl: 'https://ninja.data.olxcdn.com/ninja-real-estate.js',
        siteUrl: `${getPublicEnvConfig().env === 'PRD' ? 'www' : 'stg'}.imovirtual.com`,
    },
    settingsPageLink: '/[lang]/conta-pessoal/definicoes',
    defaultUnits: {
        distance: 'm',
        area: 'm²',
        currency: '€',
    },
    dfp: {
        networkId: '12092787/1_Imovirtual',
        type: {
            adPage: {
                halfpage: 'AdPage/Rectangle',
                billboard: 'AdPage/Billboard',
                native: 'Adpage/Native',
                textBottom: 'Adpage/TextAd_BOTTOM',
                textTop: 'Adpage/TextAd_TOP',
                calculator: 'AdPage/Fin_Calculator_AP',
            },
            searchPage: {
                additionalBox: 'Listing/Halfpage',
                rectangle: 'Listing/Rectangle',
                calculator: 'Listing/Fin_Calculator',
                billboardBottom: 'Listing/Billboard_BOTTOM',
                topBoard: 'Listing/Top_board',
                listingAd: 'Listing/ListingAd/ListingAd_820x200',
                listingAdTablet: 'Listing/ListingAd/ListingAd748x220',
                listingAdMobile: 'Listing/ListingAd/ListingAd_300x250',
            },
            myAccountPage: {},
        },
    },
    phoneNumbers: {
        countryCode: '351',
        country: 'PT',
        format: /^((2(([12]\d)|(3[1-689])|(4[1-59])|([57][1-9])|(6[1-35689])|(8[1-69])|(9[1256])))|(9[1-36]\d)|([36-8]\d{2}))\d{6}$/,
    },
    images: {
        logo: '/images/logo/imovirtual2.svg',
        nexusLogo: '/images/logo/imovirtual2.svg',
        homepageBackground: '/images/homepage/imovirtual2HomepageBackground20240905.webp',
        investmentsPageBackground: '/images/investments/imovirtual2InvestmentsBackground.webp',
    },
    navbar: [
        {
            label: {
                default: 'frontend.navbar.menu.sell',
            },
            id: 'sell',
            tracking: {
                eventName: 'for_sale_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'sale',
                    },
                    id: 'sellEstate',
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'sellEstateApartments',
                            href: '/[lang]/resultados/comprar/apartamento/todo-o-pais',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'sellEstateStudioFlat',
                            href: '/[lang]/resultados/comprar/t0/todo-o-pais',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'sellEstateHouse',
                            href: '/[lang]/resultados/comprar/moradia/todo-o-pais',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'sellEstatePlots',
                            href: '/[lang]/resultados/comprar/terreno/todo-o-pais',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'sellEstateCommercials',
                            href: '/[lang]/resultados/comprar/imoveis-comerciais/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'sellEstateOffice',
                            href: '/[lang]/resultados/comprar/imoveis-comerciais,escritorio/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'sellEstateHalls',
                            href: '/[lang]/resultados/comprar/armazens/todo-o-pais',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'sellEstateGarage',
                            href: '/[lang]/resultados/comprar/garagem/todo-o-pais',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'sellPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lisbon',
                            },
                            id: 'sellPopularLocationLisbon',
                            href: '/[lang]/resultados/comprar/apartamento/lisboa/lisboa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'lisboa/lisboa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-porto',
                            },
                            id: 'sellPopularLocationPorto',
                            href: '/[lang]/resultados/comprar/apartamento/porto/porto',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'porto/porto',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-braga',
                            },
                            id: 'sellPopularLocationBraga',
                            href: '/[lang]/resultados/comprar/apartamento/braga/braga',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'braga/braga',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-aveiro',
                            },
                            id: 'sellPopularLocationAveiro',
                            href: '/[lang]/resultados/comprar/apartamento/aveiro/aveiro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'aveiro/aveiro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-leiria',
                            },
                            id: 'sellPopularLocationLeiria',
                            href: '/[lang]/resultados/comprar/apartamento/leiria/leiria',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'leiria/leiria',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-setubal',
                            },
                            id: 'sellPopularLocationSetubal',
                            href: '/[lang]/resultados/comprar/apartamento/setubal/setubal',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'setubal/setubal',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-faro',
                            },
                            id: 'sellPopularLocationFaro',
                            href: '/[lang]/resultados/comprar/apartamento/faro/faro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'faro/faro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-investment',
                    },
                    id: 'sellInvestment',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_investments',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-apartment',
                            },
                            id: 'sellInvestmentApartment',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-house',
                            },
                            id: 'sellInvestmentHouse',
                            href: '/[lang]/resultados/comprar/moradia,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-locals',
                            },
                            id: 'sellInvestmentLocals',
                            href: '/[lang]/resultados/comprar/imoveis-comerciais,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-guide-header',
                    },
                    id: 'sellGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-imo-article1',
                            },
                            id: 'sellImoArticle1',
                            href: '/noticias/financas/saiba-qual-o-melhor-credito-habitacao-para-si/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-imo-article2',
                            },
                            id: 'sellImoArticle2',
                            href: '/noticias/financas/imoexplica-impostos-imi-imt-is-comprar-casa/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-imo-article3',
                            },
                            id: 'sellImoArticle3',
                            href: '/noticias/imobiliario/minuta-contrato-promessa-compra-e-venda/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.sell-guide-header',
                        },
                        id: 'sellGuideSeeAll',
                        href: '/noticias/',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'sale',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.rent',
            },
            id: 'rent',
            tracking: {
                eventName: 'for_rent_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'rentEstate',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'rentEstateApartments',
                            href: '/[lang]/resultados/arrendar/apartamento/todo-o-pais',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'rentEstateStudioFlat',
                            href: '/[lang]/resultados/arrendar/t0/todo-o-pais',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'rentEstateHouse',
                            href: '/[lang]/resultados/arrendar/moradia/todo-o-pais',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-room',
                            },
                            id: 'rentEstateRoom',
                            href: '/[lang]/resultados/arrendar/quarto/todo-o-pais',
                            searchData: {
                                estate: ESTATE.room,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'rentEstatePlots',
                            href: '/[lang]/resultados/arrendar/terreno/todo-o-pais',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'rentEstateCommercials',
                            href: '/[lang]/resultados/arrendar/imoveis-comerciais/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'rentEstateOffice',
                            href: '/[lang]/resultados/arrendar/imoveis-comerciais,escritorio/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'rentEstateHalls',
                            href: '/[lang]/resultados/arrendar/armazens/todo-o-pais',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'rentEstateGarage',
                            href: '/[lang]/resultados/arrendar/garagem/todo-o-pais',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.rent,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'rentPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lisbon',
                            },
                            id: 'rentPopularLocationLisbon',
                            href: '/[lang]/resultados/arrendar/apartamento/lisboa/lisboa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'lisboa/lisboa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-porto',
                            },
                            id: 'rentPopularLocationPorto',
                            href: '/[lang]/resultados/arrendar/apartamento/porto/porto',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'porto/porto',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-braga',
                            },
                            id: 'rentPopularLocationBraga',
                            href: '/[lang]/resultados/arrendar/apartamento/braga/braga',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'braga/braga',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-aveiro',
                            },
                            id: 'rentPopularLocationAveiro',
                            href: '/[lang]/resultados/arrendar/apartamento/aveiro/aveiro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'aveiro/aveiro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-leiria',
                            },
                            id: 'rentPopularLocationLeiria',
                            href: '/[lang]/resultados/arrendar/apartamento/leiria/leiria',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'leiria/leiria',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-setubal',
                            },
                            id: 'rentPopularLocationSetubal',
                            href: '/[lang]/resultados/arrendar/apartamento/setubal/setubal',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'setubal/setubal',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-faro',
                            },
                            id: 'rentPopularLocationFaro',
                            href: '/[lang]/resultados/arrendar/apartamento/faro/faro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'faro/faro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-popular-agency',
                    },
                    id: 'rentPopularAgency',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_agencies',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-lisbon',
                                mobile: 'frontend.navbar.menu.location-lisbon',
                            },
                            id: 'rentPopularAgencyLisbon',
                            href: '/lisboa/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-porto',
                                mobile: 'frontend.navbar.menu.location-porto',
                            },
                            id: 'rentPopularAgencyPorto',
                            href: '/porto/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-braga',
                                mobile: 'frontend.navbar.menu.location-braga',
                            },
                            id: 'rentPopularAgencyBraga',
                            href: '/braga/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-aveiro',
                                mobile: 'frontend.navbar.menu.location-aveiro',
                            },
                            id: 'rentPopularAgencyAveiro',
                            href: '/aveiro/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-leiria',
                                mobile: 'frontend.navbar.menu.location-leiria',
                            },
                            id: 'rentPopularAgencyLeiria',
                            href: '/leiria/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-setubal',
                                mobile: 'frontend.navbar.menu.location-setubal',
                            },
                            id: 'rentPopularAgencySetubal',
                            href: '/setubal/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-faro',
                                mobile: 'frontend.navbar.menu.location-faro',
                            },
                            id: 'rentPopularAgencyFaro',
                            href: '/faro/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-guide-header',
                    },
                    id: 'rentGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-imo-article1',
                            },
                            id: 'rentImoArticle1',
                            href: '/noticias/imobiliario/guia-para-arrendar-moradia-ou-apartamento/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-imo-article2',
                            },
                            id: 'rentImoArticle5',
                            href: '/noticias/lifestyle/carta-de-rescisao-do-contrato-de-arrendamento-tudo-o-que-precisa-de-saber/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-imo-article3',
                            },
                            id: 'rentImoArticle6',
                            href: '/noticias/imobiliario/contrato-de-arrendamento-id103/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.rent-guide-header',
                        },
                        id: 'rentGuideSeeAll',
                        href: '/noticias/',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'rent',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.primary-market',
            },
            id: 'primaryMarket',
            tracking: {
                eventName: 'primary_market_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'primaryMarketEstate',
                    tracking: {
                        eventName: 'header_categories',
                        touchPointButton: 'title_click',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'primaryMarketEstateApartments',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'primaryMarketEstateStudioFlat',
                            href: '/[lang]/resultados/comprar/t0,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'primaryMarketEstateHouse',
                            href: '/[lang]/resultados/comprar/moradia,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-investment',
                            },
                            id: 'primaryMarketInvestment',
                            href: '/[lang]/resultados/comprar/empreendimento/todo-o-pais',
                            searchData: {
                                estate: ESTATE.investment,
                                transaction: TRANSACTION.sell,
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'primaryMarketEstateCommercials',
                            href: '/[lang]/resultados/comprar/imoveis-comerciais,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'primaryMarketEstateHalls',
                            href: '/[lang]/resultados/comprar/armazens,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'primaryMarketEstateGarage',
                            href: '/[lang]/resultados/comprar/garagem,nova-construcao/todo-o-pais',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'todo-o-pais',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'primaryMarketPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lisbon',
                            },
                            id: 'primaryMarketPopularLocationLisbon',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/lisboa/lisboa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'lisboa/lisboa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-porto',
                            },
                            id: 'primaryMarketPopularLocationPorto',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/porto/porto',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'porto/porto',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-braga',
                            },
                            id: 'primaryMarketPopularLocationBraga',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/braga/braga',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'braga/braga',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-aveiro',
                            },
                            id: 'primaryMarketPopularLocationAveiro',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/aveiro/aveiro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'aveiro/aveiro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-leiria',
                            },
                            id: 'primaryMarketPopularLocationLeiria',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/leiria/leiria',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'leiria/leiria',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-setubal',
                            },
                            id: 'primaryMarketPopularLocationSetubal',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/setubal/setubal',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'setubal/setubal',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-faro',
                            },
                            id: 'primaryMarketPopularLocationFaro',
                            href: '/[lang]/resultados/comprar/apartamento,nova-construcao/faro/faro',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'faro/faro',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-market-popular-developers',
                    },
                    id: 'primaryMarketPopularDevelopers',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_developers',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-lisbon',
                                mobile: 'frontend.navbar.menu.location-lisbon',
                            },
                            id: 'primaryMarketPopularDevelopersLisbon',
                            href: '/lisboa/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-porto',
                                mobile: 'frontend.navbar.menu.location-porto',
                            },
                            id: 'primaryMarketPopularDevelopersPorto',
                            href: '/porto/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-braga',
                                mobile: 'frontend.navbar.menu.location-braga',
                            },
                            id: 'primaryMarketPopularDevelopersBraga',
                            href: '/braga/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-aveiro',
                                mobile: 'frontend.navbar.menu.location-aveiro',
                            },
                            id: 'primaryMarketPopularDevelopersAveiro',
                            href: '/aveiro/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-leiria',
                                mobile: 'frontend.navbar.menu.location-leiria',
                            },
                            id: 'primaryMarketPopularDevelopersLeiria',
                            href: '/leiria/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-setubal',
                                mobile: 'frontend.navbar.menu.location-setubal',
                            },
                            id: 'primaryMarketPopularDevelopersSetubal',
                            href: '/setubal/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-faro',
                                mobile: 'frontend.navbar.menu.location-faro',
                            },
                            id: 'primaryMarketPopularDevelopersFaro',
                            href: '/faro/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-guide-header',
                    },
                    id: 'primaryMarketGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-imo-article1',
                            },
                            id: 'primaryMarketImoArticle1',
                            href: '/noticias/imoblog/barometro-anual-2022-2023/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-imo-article2',
                            },
                            id: 'primaryMarketImoArticle2',
                            href: '/noticias/noticias/calendario-escolar-2023-dias-de-aulas-vs-dias-de-ferias/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-imo-article3',
                            },
                            id: 'primaryMarketImoArticle3',
                            href: '/noticias/imobiliario/como-funciona-o-arrendamento-com-opcao-de-compra/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.primary-guide-header',
                        },
                        id: 'primaryMarketGuideSeeAll',
                        href: '/noticias/',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            market: 'primary',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.companies',
            },
            id: 'companies',
            tracking: {
                eventName: 'companies_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-professionals',
                    },
                    id: 'companiesProfessionals',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-agency',
                            },
                            id: 'companiesProfessionalsAgency',
                            href: '/empresas/agencias-imobiliarias/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-developers',
                            },
                            id: 'companiesProfessionalsDevelopers',
                            href: '/empresas/promotores-imobiliarios/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-question',
                    },
                    id: 'companiesQuestion',
                    isDesktopOnly: true,
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.companies-add-new-ad',
                        },
                        isNexusButton: true,
                        id: 'companiesAddNewAd',
                        href: '/novo-anuncio/categoria',
                        tracking: {
                            eventName: 'posting_form_click',
                            touchPointButton: 'header_companies',
                        },
                    },
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-guide-header',
                    },
                    id: 'companiesGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-imo-article1',
                            },
                            id: 'companiesImoArticle1',
                            href: '/noticias/noticias/eu-sou-agente-imobiliario/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-imo-article2',
                            },
                            id: 'companiesImoArticle2',
                            href: '/noticias/noticias/o-que-e-um-imovel-em-off-market/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            shoudlBeMasked: true,
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-imo-article3',
                            },
                            id: 'companiesImoArticle3',
                            href: '/noticias/imobiliario/academia-imovirtual-roadshow-um-evento-imperdivel/',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            shoudlBeMasked: true,
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.companies-guide-header',
                        },
                        id: 'companiesGuideSeeAll',
                        href: '/noticias/',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header_companies',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
    ],
    navUserMenu: {
        businessMember: [],
        unconfirmedUser: [
            {
                href: '/en/conta/registrar',
                label: 'frontend.navbar.menu.complete-registration',
            },
        ],
        regularUser: [
            {
                localisedHref: '/[lang]/conta-pessoal',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/contapessoal/mensagens',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/conta-pessoal/pagamentos/historico',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                href: '/contapessoal/definicoes-de-conta',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessUser: [
            {
                localisedHref: '/[lang]/conta-pessoal/empresa/resumo',
                label: 'frontend.navbar.menu.summary',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_summary',
                },
            },
            {
                localisedHref: '/[lang]/conta-pessoal',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/contapessoal/mensagens',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/conta-pessoal/empresa/pagamentos',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                localisedHref: '/[lang]/conta-pessoal/definicoes',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessAdmin: [],
        favourites: [
            {
                href: '/[lang]/guardados/anuncios',
                label: 'frontend.navbar.menu.favourite-adverts',
            },
            {
                href: '/[lang]/guardados/pesquisas',
                label: 'frontend.navbar.menu.favourite-searches',
            },
        ],
    },
    dateInput: {
        format: 'DD/MM/YYYY',
        placeholder: 'dd/mm/aaaa',
    },
    atlasRedirects: {
        activateAd: '/pagamento/selecao-de-pagamento/?id[0]={id}',
        editAd: '/novo-anuncio/edit/{id}/',
        extendAd: '/pagamento/selecao-de-pagamento/?prolong[0]=1&id[0]={id}',
        promoteAd: '/pagamento/selecao-de-pagamento/?id[0]={id}',
        adSingleMessage: '/contapessoal/responder/{id}',
        adMessages: '/contapessoal/mensagens/{id}',
        addNewUnit: '/comprar/apartamento/novo-anuncio/?parent_id={parentId}',
        agencyRegistration: '/conta/registo-de-empresa/agencias-imobiliarias/',
        developerRegistration: '/conta/registo-de-empresa/promotores-imobiliarios/',
        copyAd: '/comprar/apartamento/novo-anuncio/?parent_id={parentId}&id={id}&copying=1',
        moreArticles: '/noticias',
        offers: '/anuncios/?search%5Bhomepage_ads%5D=1',
        developersPage: '/empresas/promotores-imobiliarios/',
        analytics: '',
        registrationSuccess: '/conta/verificaremail/?type=registerbusiness',
        businessActivation: '/conta/ativacao',
        projectPostingForm: '',
        hciamLoginPage: '/api/internal/hciam/signin',
    },
    isProjectPostingFormMigrated: true,
    feedbackButton: {
        link: 'https://ajuda.imovirtual.com/imovirtualhelp/s/contactsupport',
    },
    baxter: {
        urlInit: 'https://cdn.slots.baxter.olx.org/imovirtualpt/web/release/init.min.js',
        urlStyling: 'https://cdn.slots.baxter.olx.org/imovirtualpt/web/release/init.css',
    },
    shouldShowSocialButtonsOnTopOnLoginModal: false,
    privacyPolicyLink: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/novo-poltica-de-privacidade-V20IMO',
    termsAndConditionsLink:
        'https://ajuda.imovirtual.com/imovirtualhelp/s/article/termos-e-condies-para-clientes-individuais-V32IMO',
    features: {
        addOns: { enabledForUserTypes: [] },
    },
    externalUrl: {},
    regulationPolicyLink: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPCTCA2',
    cookiesPolicyLink: 'https://ajuda.imovirtual.com/imovirtualhelp/s/article/kA009000001vPCLCA2',
    shouldUnregisterServiceWorker: false,
    shouldUseTypologyInsteadOfNumberOfRooms: true,
    postCode: {
        placeholder: '1234-567',
    },
    topLocations: { atlasLocations: [], locationServiceLocations: [] },
    validation: {
        postCode: /^[1-9]\d{3}-\d{3}$/,
        tin: 'tin-pt',
    },
};
